@font-face {
      font-family: entypo;
      src: url('../fonts/entypo.ttf'),
            url('../fonts/entypo.eot'); /* IE9 */
      -webkit-font-smoothing: antialiased;
}

@font-face {
      font-family: entypo-social;
      src: url('../fonts/entypo-social.ttf'),
            url('../fonts/entypo-social.eot'); /* IE9 */
      -webkit-font-smoothing: antialiased;
}

/* open-sans-300 - latin */
@font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 300;
      src: url('../fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
      src: local('Open Sans Light'), local('OpenSans-Light'),
            url('../fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/open-sans-v15-latin-300.woff') format('woff'), /* Modern Browsers */
            url('../fonts/open-sans-v15-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 400;
      src: url('../fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
      src: local('Open Sans Regular'), local('OpenSans-Regular'),
            url('../fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/open-sans-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
            url('../fonts/open-sans-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      src: url('../fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
      src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
            url('../fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/open-sans-v15-latin-600.woff') format('woff'), /* Modern Browsers */
            url('../fonts/open-sans-v15-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      src: url('../fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
      src: local('Open Sans Bold'), local('OpenSans-Bold'),
            url('../fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/open-sans-v15-latin-700.woff') format('woff'), /* Modern Browsers */
            url('../fonts/open-sans-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 800;
      src: url('../fonts/open-sans-v15-latin-800.eot'); /* IE9 Compat Modes */
      src: local('Open Sans Bold'), local('OpenSans-Bold'),
            url('../fonts/open-sans-v15-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/open-sans-v15-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
            url('../fonts/open-sans-v15-latin-800.woff') format('woff'), /* Modern Browsers */
            url('../fonts/open-sans-v15-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
            url('../fonts/open-sans-v15-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
      font-family: 'Material Icons';
      font-style: normal;
      font-weight: 400;
      src: local('Material Icons'),
            local('MaterialIcons-Regular'),
            url(../fonts/MaterialIconsOutlined-Regular.otf) format('truetype');
      }

      .material-icons {
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 24px;  /* Preferred icon size */
            display: inline-block;
            line-height: 1;
            text-transform: none;
            letter-spacing: normal;
            word-wrap: normal;
            white-space: nowrap;
            direction: ltr;
            
            /* Support for all WebKit browsers. */
            -webkit-font-smoothing: antialiased;
            /* Support for Safari and Chrome. */
            text-rendering: optimizeLegibility;
            
            /* Support for Firefox. */
            -moz-osx-font-smoothing: grayscale;
            
            /* Support for IE. */
            font-feature-settings: 'liga';
      }

.entypo { font-family: entypo; }

h1, h2, h3, h4, h5, h6 {
      font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 800;
      line-height: 1.1;
}
