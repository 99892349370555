body {
  height: 100%;
  max-height: 100%;
  font: .625em/1 'Open Sans', sans-serif;
  letter-spacing: 0.01rem;
  font-size: 1.6em;
  line-height: 1.75em;
  color: #3A4145;
}

a {
  padding: 0;
  color: #4A4A4A;
  text-decoration: none;
  //background: linear-gradient(to bottom,transparent 0,transparent 85%,#FFC0CB 60%,#FFC0CB 100%);
  transition: all 0.15s linear;
}

a:hover {
  //color: #fff;
  //background-color: #FFC0CB;
  //background: none;
  color: #ff90a3;
}

.header {
  position: fixed;
  z-index: 20;
  width: 100%;
  height: 7rem;
  border-bottom: #EBF2F6 1px solid;
  padding: 1rem 0 1rem 2rem;
  background-color:hsla(0,0%,100%,.95);
}

.header:after {
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  border: #E7EEF2 1px solid;
  position: relative;
  bottom: -20px;
  left: 50%;
  margin-left: -25px;
  background-color:hsla(0,0%,100%,.95);
  border-radius: 100%;
}

.header h1 {
  margin: 0 0 1rem 0;
  padding: 0;
  font: 400 32px/1 'Open Sans', sans-serif;
  letter-spacing: -1px;
  color: #333;
  text-decoration: none;
}

.header p {
  margin: 0;
  padding: 0 0 0 4px;
  font: 700 14px/1 'Open Sans', sans-serif;
  color: #444444;
}

.red {
  color: red;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 700px;
  padding: 0 10px;
}

#navView {
  margin-top: 3rem;
  margin-bottom: 3.6rem;
  height: 80px;
  text-align: center;
  padding-top: 50px;
  background: url(/assets/images/background/moeve.jpg) no-repeat -1300px -550px;
}

.content {
  padding-top: 10rem;
}

.post:not(:first-child) {
  margin-top: 3.6rem;
}
.post:last-child {
  margin-bottom: 3.6rem;
}

//.post-content a {
//  font-weight: 600;
//}

.post-content a {
  padding: 0;
  color: rgb(255, 144, 163, 1);
  background: linear-gradient( 90deg, rgba(255, 144, 163, 0.2), rgba(255, 144, 163, 0.25) ) no-repeat 0 100%;
  background-size: 100% 5%;
  background-position-y: 90%;
  text-decoration: none;
  transition: all 0.2s 0.1s;
  font-weight: 600;
}

.post-content a:hover {
  padding: 2px 0;
  background: linear-gradient( 90deg, rgba(255, 144, 163, 0.2), rgba(255, 144, 163, 0.25) ) no-repeat 0 100%;
  background-position-y: 100%;
  background-size: 100% 100%;
}

.post-content pre {
  margin-left: 0.4rem;
  padding: 10px;
  border-left: 0.4rem solid #89cff0;
}

.pagination {
  margin-bottom: 3.6rem;
}

.pagination-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

blockquote {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1.75rem 0.4rem 1.75rem 0.4rem;
  padding: 0 0 0 1.75rem;
  border-left-width: 0.3125rem;
  border-radius: 0.3125rem 0.1875rem 0.1875rem 0.3125rem;
  border-left: #FFC0CB 0.4rem solid;
}

.alert {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 1.75rem 0.4rem 1.75rem 0.4rem;
  padding: 1.25rem 0 1.25rem 1.25rem;
}

.alert-note {
  color: #3c763d;
  background-color: #dff0d8;
  border-left-width: 0.3125rem;
  border-radius: 0.3125rem 0.1875rem 0.1875rem 0.3125rem;
  border-color: #d6e9c6;
  border-left: #d6e9c6 0.4rem solid;
}

.alert-note-icon {
  display: block;
  float: left;
  padding-top: 0.20rem;
  padding-right: 1rem;
  font-family: 'Material Icons';
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-left-width: 0.3125rem;
  border-radius: 0.3125rem 0.1875rem 0.1875rem 0.3125rem;
  border-color: #bce8f1;
  border-left: #bce8f1 0.4rem solid;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-left-width: 0.3125rem;
  border-radius: 0.3125rem 0.1875rem 0.1875rem 0.3125rem;
  border-color: #ebccd1;
  border-left: #ebccd1 0.4rem solid;
}

.alert-warning {
  color: #3A4145;
  background-color: #fcf8e3;
  border-left-width: 0.3125rem;
  border-radius: 0.3125rem 0.1875rem 0.1875rem 0.3125rem;
  border-color: #faebcc;
  border-left: #faebcc 0.4rem solid;
}

.post .post-content {
  padding-bottom: 0.1rem;
}

.single-post {
  padding-top: 2.5rem;
}

article.post:first-child {
  padding-top: 0;
}


article.post h2,
article.post p {
  padding-left: 4px;
}

div.post-title h2,
h2.post-title {
  margin-bottom: 0;
  font-size: 3.5rem;
}

article.post header.post-header div.post-featured-image {
  text-align: center;
}

article.post header.post-header div.post-featured-image img {
  max-width: 100%;
  width: 760px;
  opacity: 75%;
}

article.post header.post-header p.public-date {
  margin-top: 0;
  font-size: 0.8em;
}

footer.site-footer div.wrapper, article .post-header, .pagination {
  padding-top: 2rem;
  border-top: #EBF2F6 1px solid;
}

article.post:first-child .post-header {
  padding-top: 10rem;
}

footer.site-footer div.wrapper:before, article.post header.post-header:before, .pagination:before {
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  border: #E7EEF2 1px solid;
  position: relative;
  top: -25px;
  left: 50%;
  margin-left: -5px;
  background: #FFF;
  border-radius: 100%;
}

article.post:first-child header.post-header,
article.post:first-child header.post-header:before {
  border: none!important;
}

footer.site-footer {
  position: relative;
  margin-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.2rem;
  line-height: 1.75em;
  text-align: center;
}

#contact ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.smbtn {
  position: relative;
  margin: 0px auto;
  width: 250px;
}

.smbtn:first-child {
  margin-top: 50px;
}

.smbtn a {
  display: block;
  margin: 0px auto;
  margin-bottom: 12px;
  padding: 5px 15px;
  width: 250px;
  border-radius: 50rem !important;
  border: 1px solid #FFC0CB;
  background: none !important;
}

.smbtn a:hover {
  border-color: #99ddff;
  padding: 5px 15px !important;
}

.smbtn img {
  position: absolute;
  top: 8px;
  right: -10px;
  height: 25px;
}

.reviewRating {
  font-size: 2rem;
}

.ratingUnset {
  color: rgba(255, 144, 163, 0.25);
}

.ratingSet {
  color: rgba(255, 144, 163, 1);
}

div#social-media {
  margin-top: 18px;
}

div#social-media a {
  margin: 0 6px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.imageDescription {
  font-size: 0.7em;
  text-align: center;
  margin-top: 0rem;
}